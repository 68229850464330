import gsap from "../libs/gsap.min";

let width = gsap.quickTo(".footer__progress-bar", "width", {
    duration: 1.5,
    ease: Power4.easeOut,
});

const initProgressBar = () => {
    let progressBarTimeLine = gsap.timeline();

    window.addEventListener("scroll", () => progressBar());
    window.addEventListener("resize", () => progressBar());
    const progressBar = () => {
        let winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
        let height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        let scrolled =
            (winScroll / height) *
            document.querySelector(".footer").clientWidth;
        width(scrolled);
    };
};

export { initProgressBar };
