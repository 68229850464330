import gsap from "../libs/gsap.min";

const cursor = document.querySelector(".cursor");

const links = document.querySelectorAll("a");
const buttons = document.querySelectorAll("button");

let left = gsap.quickTo(cursor, "left", { duration: 0.3, ease: "power3" });
let top = gsap.quickTo(cursor, "top", { duration: 0.3, ease: "power3" });

const initCursor = () => {
    window.addEventListener("mousemove", (e) => {
        left(e.clientX);
        top(e.clientY);
        gsap.set(cursor, { opacity: 1, duration: 0.3, ease: "power3" });
    });

    links.forEach((link) =>
        link.addEventListener("mouseenter", (e) => {
            gsap.to(cursor, { scale: 1.5, duration: 0.3, ease: "power3" });
        })
    );

    links.forEach((link) =>
        link.addEventListener("mouseleave", (e) => {
            gsap.to(cursor, { scale: 1, duration: 0.3, ease: "power3" });
        })
    );

    buttons.forEach((button) =>
        button.addEventListener("mouseenter", (e) => {
            gsap.to(cursor, { scale: 1.5, duration: 0.3, ease: "power3" });
        })
    );

    buttons.forEach((button) =>
        button.addEventListener("mouseleave", (e) => {
            gsap.to(cursor, { scale: 1, duration: 0.3, ease: "power3" });
        })
    );

    window.addEventListener("mousedown", () => {
        gsap.to(cursor, { scale: 2, duration: 0.3, ease: "power3" });
    });

    window.addEventListener("mouseup", () => {
        gsap.to(cursor, { scale: 1, duration: 0.3, ease: "power3" });
    });
};

export { initCursor };
