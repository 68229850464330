import gsap from "../libs/gsap.min";
import ScrollSmoother from "../libs/ScrollSmoother.min";

gsap.registerPlugin(ScrollSmoother);

const initScrollSmoother = () => {
    ScrollSmoother.create({
        wrapper: ".page",
        content: ".main",
        smooth: 1.5,
        effects: true,
    });
};

export { initScrollSmoother };
