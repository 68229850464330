import axios from "axios";
import gsap from "../libs/gsap.min";

let form = document.querySelector(".information_form");
let submit = document.querySelectorAll(".information_form_submit_span");

const TOKEN = "5632085819:AAFJxHz6Yqu1gmQsnXEZ6PG9N5Rr3t5vNrU";
const CHAT_ID = "-1001628805979";
const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

let message = "";

const submitForm = (e) => {
    e.preventDefault();
    message = `<b>Заявка з сайту!</b>\nВідправник: ${form.name.value}\nПошта відправника: ${form.email.value}\nТип послуги: ${form.type.value}\nБюджет: ${form.budget.value}\nПовідомлення: ${form.description.value}`;
    axios
        .post(URI_API, {
            chat_id: CHAT_ID,
            parse_mode: "html",
            text: message,
        })
        .then((response) => {
            form.name.value = "";
            form.email.value = "";
            form.type.forEach((radio) => (radio.checked = false));
            form.budget.forEach((radio) => (radio.checked = false));
            form.description.value = "";
            gsap.to(".information_form_submited", {
                opacity: 1,
                zIndex: 1,
            });
            submit.forEach(
                (span) =>
                    (span.innerHTML =
                        "Thank you! Your application has been accepted")
            );
        })
        .catch((err) => {
            gsap.to(".information_form_error", {
                opacity: 1,
                zIndex: 1,
            });
            submit.forEach(
                (span) => (span.innerHTML = "Application failed...Try again")
            );
        })
        .finally(() => {
            form.name.disabled = true;
            form.email.disabled = true;
            form.type.forEach((input) => (input.disabled = true));
            form.budget.forEach((input) => (input.disabled = true));
            form.description.disabled = true;
            form.submit.disabled = true;
        });
};

const initForm = () => {
    form.addEventListener("submit", submitForm);
};

export { initForm };
