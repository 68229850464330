const button = document.querySelector(".footer_burger-button");
const menu = document.querySelector(".footer");
const content = document.querySelector(".footer_burger-menu");
const links = document.querySelectorAll(".footer_menu_link");

const openMenu = () => {
    menu.classList.toggle("active");
    content.classList.toggle("active");
};

const resetMenu = () => {
    menu.classList.remove("active");
    content.classList.remove("active");
};

const initMenu = () => {
    window.addEventListener("resize", resetMenu);

    button.addEventListener("click", openMenu);
    links.forEach((link) => link.addEventListener("click", openMenu));
};

export { initMenu };
