import gsap from "../libs/gsap.min";
import ScrollTo from "../libs/ScrollTo.min";

const links = document.querySelectorAll("a");

const scrollTo = (e, link) => {
    const scrollToHere = link.getAttribute("href");
    if (scrollToHere.charAt(0) === "#" && scrollToHere.length > 1) {
        e.preventDefault();
        gsap.to(window, {
            duration: 1.5,
            scrollTo: scrollToHere,
            ease: "power4",
        });
    }
};

const initScrollTo = () => {
    links.forEach((link) =>
        link.addEventListener("click", (e) => scrollTo(e, link))
    );
};

export { initScrollTo };
