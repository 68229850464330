const timeInit = () => {
    let time = new Date();
    let timeText = document.querySelector(".header__time");

    refreshTime(time, timeText);

    setInterval(() => {
        time = new Date();
        refreshTime(time, timeText);
    }, 1000);
};

const refreshTime = (time, timeText) => {
    timeText.textContent = time.toLocaleTimeString("en-US", {
        timeZone: "Europe/Kyiv",
        hour: "2-digit",
        minute: "2-digit",
    });
};

export { timeInit };
