const linkItems = [...document.querySelectorAll(".link3D")];

const init3DHoverEffect = () => {
    linkItems.forEach((item) => {
        let word = item.children[0].children[0].innerText.split("");
        item.children[0].innerHTML = "";
        word.forEach((letter, index) => {
            item.children[0].innerHTML += `<span style="--index: ${index};">${letter}</span>`;
        });
        let cloneDiv = item.children[0].cloneNode(true);
        cloneDiv.style.position = "absolute";
        cloneDiv.style.left = "0";
        cloneDiv.style.top = "0";
        item.appendChild(cloneDiv);
    });
};

export { init3DHoverEffect };
